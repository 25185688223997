import React, {useState} from 'react';
import Modal from 'react-modal';

import './App.css';

import axios from 'axios'


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '50px'
  },
};


function App() {


  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [message, setMessage] = useState('');  
  
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenSent, setIsOpenSent] = useState(false);
  const [answer, setAnswer] = useState('');

  let ModalData = () => {
    return(
      <div>
        <h3>Thank you for showing interest in our project!</h3>
        <p>One last thing. Please answer the question to finish.</p>

        <p className="mt-4">What is the name of this website? <span style={{color: 'rgb(171 171 171)'}}>*(explore.ba)</span></p>
        <div className="d-flex">
          <input className="answer-input" type="text" placeholder="Full Name" value={answer} onInput={e => {setAnswer(e.target.value)}}/>
          <button type="button" className="btn btn-primary ms-3" onClick={() => {checkAnswer()}}>VERIFY</button>
        </div>
      </div>
    );
  }



  const sendEmail = () =>{
    console.log("Hello!")
    console.log("Name : " + name);
    console.log("Email : " + email);
    console.log("Company : " + company);
    console.log("Message : " + message);

    axios.post('http://localhost:3001/send', {
      name: name,
      email: email,
      company: company,
      message: message
    })
    .then(function (response) {
      console.log(response);
    });
  }

  const checkAnswer = () => {
    console.log("You ran me!")
    if(answer === "explore.ba"){
      console.log("Yup thats the answer..");
      closeModal();
      openModalSent();
    }else{
      console.log("Not the answer buddy.");
      console.log(answer)
    }
  }


  function openModal() {
    setIsOpen(true);
  }
  function openModalSent() {
    setIsOpenSent(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    
  }

  function closeModal() {
    setIsOpen(false);
    setIsOpenSent(false);
  }


  return (
    <div className="App">
        <section className="header" id="start">
          <div className="header-bg-image">
            <div className="container">
              <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid">
                  <a className="navbar-brand" href="#start">
                    <img alt="empty" src="logo.png" width="50" height="50"></img>
                  </a>
                  <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                      <li className="nav-item me-4">
                        <a className="nav-link active text-light" aria-current="page" href="#start">Home</a>
                      </li>
                      <li className="nav-item me-4">
                        <a className="nav-link active text-light" aria-current="page" href="#team-members">Our Team</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link active text-light" aria-current="page" href="#contact-us">Contact</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
              <div className="row align-items-center justify-content-between py-5">
                <div className="col-md-6 slide-left-animation">
                  <h3 className="text-light header-main-text">Let us create your trip around Bosnia!</h3>
                  <p  className="text-light header-sub-text">Our app helps you create the ideal trip using Artificial Intelligence. <br/> Learn more about our work!</p>
                </div>
                <div className="col-md-6 slide-right-animation">
                  <img alt="empty" src="./assets/images/img1.png" className="header-image d-block mx-auto"/>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="whyUse slide-bottom-animation">
          <div className="container">
            <div className="row mt-5">
              <div className="text-center mb-5">
                <h1>Why use Explore.ba ?</h1>
                <p>With a brand new approach to trip planning, using Explore.ba has a number of benefits</p>
              </div>
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body">
                    <img alt="empty" src="./assets/images/tap.png" className="card-image d-block mx-auto"/>
                    <h5 className="card-title text-center">Easy to use</h5>
                    <p className="card-text text-center">Our app is completely user-friendly despite all complex features</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body">
                    <img alt="empty" src="./assets/images/ai.png" className="card-image d-block mx-auto"/>
                    <h5 className="card-title text-center">Artificial Intelligence</h5>
                    <p className="card-text text-center">The use of AI allows execution of multiple features our app contains</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body">
                    <img alt="empty" src="./assets/images/share.png" className="card-image d-block mx-auto"/>
                    <h5 className="card-title text-center">Save time & seize opportunities</h5>
                    <p className="card-text text-center">Save time when planning and travelling while visiting our partners.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="planning-section">
          <div className="container">
            <div className="row">
              <div className="col-md-6 my-auto">
                <h1 className="text-light trip-input-main-text">Save time & seize opportunities</h1>
                <p className="text-light trip-input-sub-text">Save time when planning. Insert necessary trip details and implemented AI algorithms will do the rest. Explore.ba saves your time!</p>
              </div>
              <div className="col-md-6">
                <img alt="empty" src="./assets/images/tripplanning.png" className="tripplanning-image d-block mx-auto img-fluid"/>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <img alt="empty" src="./assets/images/search-book.png" className="tripplanning-image img-fluid"/>
              </div>
              <div className="col-md-6 my-auto search-and-book">
                <h1 className="search-main-text">Use the advantage of the features and explore the country.</h1>
                <p className="search-sub-text">With our app you can find hidden gems as well as easily find transport to your desired location.</p>
              </div>
            </div>
          </div>
        </section>
        <section className="planning-section">
          <div className="container">
            <div className="row">
              <div className="col-md-6 my-auto">
                <h1 className="text-light planning-main-text">Plan your trip and access it any time.</h1>
                <p className="text-light planning-sub-text">Using our app you can plan out your trip around Bosnia and Herzegovina, and access it any time.</p>
              </div>
              <div className="col-md-6">
                <img alt="empty" src="./assets/images/tripplanned.png" className="tripplaned-image d-block mx-auto"/>
              </div>
            </div>
          </div>
        </section>
        <section className="mt-5" id="team-members">
          <div className="container my-5">
            <div className="row">
              <div className="mb-5">
                <h1 className="text-center">Our Team Members</h1>
                <p className="text-center">We're lead by a team who constantly questions, tinkers and challenges to unlock great creativity around every turn.</p>
              </div>
            </div>
            <div className="row mx-auto">
              <div className="col-md-4">
                <div className="team-member mt-3">
                  <div className="team-image-container">
                    <img alt="empty" src="./assets/images/azra.jpg" className="team-image img-fluid"/>
                  </div>
                  <div className="team-text d-flex flex-column align-items-center justify-content-center">
                    <h5 className="text-center text-light">Azra Velić</h5>
                    <h6 className="text-center text-light">CEO</h6>
                    <div className="row">
                      <div className="col">
                        <a href="https://www.instagram.com/azraaaav/"><img alt="empty" src="./assets/images/instagram.png" className="team-icons img-fluid"/></a>
                      </div>
                      <div className="col">
                        <a href="https://www.linkedin.com/in/azravelic/"><img alt="empty" src="./assets/images/linkedin.png" className="team-icons img-fluid"/></a>
                      </div>
                      <div className="col">
                        <a href="mailto: azra1velic@gmail.com"><img alt="empty" src="./assets/images/gmail.png" className="team-icons"/></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
              <div className="team-member mt-3">
                  <div className="team-image-container">
                    <img alt="empty" src="./assets/images/emin.jpg" className="team-image img-fluid"/>
                  </div>
                  <div className="team-text d-flex flex-column align-items-center justify-content-center">
                    <h5 className="text-center text-light">Emin Nefić</h5>
                    <h6 className="text-center text-light">Lead Developer</h6>
                    <div className="row">
                      <div className="col">
                        <a href="https://www.instagram.com/nefiich/"><img alt="empty" src="./assets/images/instagram.png" className="team-icons"/></a>
                      </div>
                      <div className="col">
                        <a href="https://www.linkedin.com/in/nefiich/"><img alt="empty" src="./assets/images/linkedin.png" className="team-icons"/></a>
                      </div>
                      <div className="col">
                        <a href="https://github.com/Nefiich"><img alt="empty" src="./assets/images/github.png" className="team-icons"/></a>
                      </div>
                      <div className="col">
                        <a href="mailto: emin.nefic@gmail.com"><img alt="empty" src="./assets/images/gmail.png" className="team-icons"/></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
              <div className="team-member mt-3">
                  <div className="team-image-container">
                    <img alt="empty" src="./assets/images/muhamed.jpg" className="team-image img-fluid"/>
                  </div>
                  <div className="team-text d-flex flex-column align-items-center justify-content-center">
                    <h5 className="text-center text-light">Muhamed Zahirović</h5>
                    <h6 className="text-center text-light">Operations Manager</h6>
                    <div className="row">
                      <div className="col">
                        <a href="https://www.instagram.com/zahiroviccc_m/"><img alt="empty" src="./assets/images/instagram.png" className="team-icons img-fluid"/></a>
                      </div>
                      <div className="col">
                        <a href="mailto: mzahirovic41.com"><img alt="empty" src="./assets/images/gmail.png" className="team-icons"/></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="contact-us mt-5" id="contact-us">
         <div className="contact-us-bg-image">
          <div className="container">
            <h1 className="text-center special-margin-top">Have some questions?</h1>
            <div className="d-flex justify-content-center mb-5">
              <img alt="empty" src="./assets/images/mail-black.png" className="small-mail-icon mt-1"/>
              <a href="mailto: contact@explore.ba"><h5 className="ms-2">: contact@explore.ba</h5></a>
            </div>
            <div className="row mt-5">
                <div className="col-md-6">
                  <img alt="empty" src="./assets/images/contact-us-email.png" className="big-mail-icon img-fluid"/>
                </div>
                <div className="col-md-6 my-auto align-self-end">
                  <form className="form-container">
                    <div className="input-container">
                      <input className="message-input" type="text" placeholder="Full Name" value={name} onInput={e => setName(e.target.value)}/>
                    </div>
                    <div className="input-container mt-3">
                      <input className="message-input" type="text" placeholder="Email" value={email} onInput={e => setEmail(e.target.value)}/>
                    </div>
                    <div className="input-container mt-3">
                      <input className="message-input" type="text" placeholder="Company" value={company} onInput={e => setCompany(e.target.value)}/>
                    </div>
                    <div className="input-container mt-3 message-height">
                      <textarea suppressContentEditableWarning={true} className="message mt-2" contentEditable="true" placeholder="Message" value={message} onInput={e => setMessage(e.target.value)}/>
                    </div>
                    <button type="button" className="btn btn-primary mt-3 send-button" onClick={openModal}>SEND MESSAGE</button>
                  </form>
                </div>
            </div>
          </div>
         </div>
         <div>
          
         <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Modal"
          ariaHideApp={false}
        >
          {ModalData()}
        </Modal>
        <Modal
          isOpen={modalIsOpenSent}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Modal"
          ariaHideApp={false}
        >
          <div style={{paddingRight:50, paddingLeft: 50}}>
            <img alt="empty" src="./assets/images/sent-mail.png" className="mx-auto my-3 sent-email-img d-block"/>
            <h2 className="text-center">Thank you</h2>
            <p className="text-center" style={{color: 'rgb(171 171 171)'}}>Your email has been recieved.<br/>
            We will be in touch and contact you soon!</p>
            <button type="button" className="btn btn-primary mt-3 back-to-site-button mx-auto" onClick={closeModal}>BACK TO SITE</button>
          </div>
        </Modal>
        </div>
        </section>
        <section className="footer">
          <div className="container">
            <div className="row special-padding-top">
              <div className="col-md-3 pe-4 mt-5">
                <div className="logo-text">
                  <img alt="empty" src="logo.png" className="footer-logo"/>
                </div>
                <p className="mt-4 text-light footer-logo-text">We are your bestfriend when you want to plan a trip in Bosnia. <br/> Seamlessly plan and visit!</p>
                <div className="footer-icons-container">
                  <a href="https://www.instgram.com/team.explore.ba/"><img alt="empty" src="./assets/images/facebook.png" className="footer-icon"/></a>
                  <a href="https://www.instgram.com/team.explore.ba/"><img alt="empty" src="./assets/images/instagram.png" className="footer-icon ms-3"/></a>
                  <a href="https://www.instgram.com/team.explore.ba/"><img alt="empty" src="./assets/images/twitter.png" className="footer-icon ms-3"/></a>
                </div>
              </div>
              <div className="col-md-3 mt-5">
                <h3 className="text-light">Resources</h3>
                <p className="footer-list-item mt-3">Help</p>
                <p className="footer-list-item">Events</p>
                <p className="footer-list-item">Open Sources</p>
                <p className="footer-list-item">Documentation</p>
              </div>
              <div className="col-md-3 mt-5">
              <h3 className="text-light">Company</h3>
                <p className="footer-list-item mt-3">About Us</p>
                <p className="footer-list-item">Customers</p>
                <p className="footer-list-item">Community</p>
                <p className="footer-list-item">Our Team</p>
              </div>
              <div className="col-md-3 mt-5">
              <h3 className="text-light">Info</h3>
                <div className="footer-list-item-location-container mt-3">
                  <img alt="empty" src="./assets/images/phone-call.png" className="footer-list-item-logo mt-1"/>
                  <p className="footer-list-item ms-2">+387 62 845 161</p>
                </div>
                <div className="footer-list-item-location-container">
                  <img alt="empty" src="./assets/images/mail.png" className="footer-list-item-logo mt-1"/>
                  <p className="footer-list-item ms-2">contact@explore.ba</p>
                </div>
                <div className="footer-list-item-location-container">
                  <img alt="empty" src="./assets/images/internet.png" className="footer-list-item-logo mt-1"/>
                  <p className="footer-list-item ms-2">explore.ba</p>
                </div>
              </div>
            </div>
          </div>
        </section>
    </div>
  );
}

export default App;
